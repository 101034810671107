

































































import { Component, Vue } from 'vue-property-decorator';
import UserAccountModule from '@/store/modules/UserAccountModule';
import AuthModule from '@/store/modules/AuthModule';
import accounterrors from '@/pages/account/components/AccountErrors.vue';
@Component({
  components: {
    accounterrors,
  },
})
export default class DeleteAccountPage extends Vue {
  reasonforleaving = '';
  async deleteAccount() {
    await UserAccountModule.deleteAccount(this.reasonforleaving);
    if (UserAccountModule.deleteAccountSucceeded) {
      AuthModule.logoutWithRedirect('/goodbye');
    }
  }

  get deleteAccountProcessing() {
    return UserAccountModule.deleteAccountProcessing;
  }

  get deleteAccountError() {
    return UserAccountModule.deleteAccountError;
  }
}
